import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import Tab from "../components/tab";
import { ToastContainer, toast } from "react-toastify";
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";
import MicIcon from "@mui/icons-material/Mic";
import { useDispatch, useSelector } from "react-redux";
import { dispatchBusinessFinancials, returnHome } from "../../../Redux/ActionCreator";
import { getOneFromServerCache, saveIntoServerCache } from "../../../utils/saveIntoCache";

export default function BusinessFinancial1({ prevStep, nextStep }) {
  const flowLanguage = "English";
  const showButtomBTN = true;
  const [funding, setFunding] = useState("");
  const [isListening, setIsListening] = useState(false);
  const { transcript, listening, resetTranscript, browserSupportsSpeechRecognition } =
    useSpeechRecognition();
  const dispatch = useDispatch();
  const info = useSelector((state) => state.businessFinancials);

  useEffect(() => {
    if (info.message !== null && info.message.funding) {
      setFunding(info.message.funding);
    } else {
      let cacheInfo = getOneFromServerCache("businessFinancials");
      cacheInfo
        .then((res) => res.json())
        .then((res) => {
          if (res.success === true) {
            if (res.data !== null && res.data !== undefined) {
              let resObj = JSON.parse(res.data);
              dispatch(dispatchBusinessFinancials(resObj));
              setFunding(resObj?.funding || "");
            }
          } else if (res.success === false && res.message === "Unauthorized") {
            dispatch(returnHome(res.status));
          }
        })
        .catch((err) => {
          console.error("Error getting business plan info", err);
        });
    }
  }, []);

  useEffect(() => {
    if (transcript !== null && transcript !== undefined && transcript !== "") {
      setFunding(transcript);
    }
  }, [transcript]);

  const handleMicClick = () => {
    if (!browserSupportsSpeechRecognition) {
      alert("Your browser does not support speech recognition.");
      return;
    }

    if (listening) {
      SpeechRecognition.stopListening();
    } else {
      resetTranscript();
      SpeechRecognition.startListening({ continuous: true, language: flowLanguage === "English" ? "en-US" : "es-ES" });
    }
    setIsListening(!isListening);
  };

  const handleSubmit = () => {
    if (!funding.trim()) {
      toast.error("Please specify the funding amount needed");
      return;
    }

    const businessPlanFinancial = {
      funding: funding,
      funding_use: info.message?.funding_use ? info.message.funding_use : '',
      revenue_stream: info.message?.revenue_stream ? info.message.revenue_stream : '',
      operating_expense: info.message?.operating_expense ? info.message.operating_expense : '',
      pricing_strategy: info.message?.pricing_strategy ? info.message.pricing_strategy : ''
    };

    dispatch(dispatchBusinessFinancials(businessPlanFinancial));
    saveIntoServerCache("businessFinancials", businessPlanFinancial);
    nextStep();
  };

  return (
    <div className="bg-[#F7F5FF] min-h-screen flex justify-center p-4 md:p-6 lg:p-8 xl:p-10">
      <div className="flex flex-col gap-y-4 w-full max-w-max">
        {/* Tabs Section */}
        <div className="flex justify-center">
          <div className="flex bg-white rounded-md px-6 py-[0.85rem] gap-x-3 overflow-x-hidden">
            <Tab label="Business Overview" current={false} completed={true} />
            <Tab current={false} label="Market and Competitors" completed={true} />
            <Tab current={false} label="Team and Structure" completed={true} />
            <Tab current={false} label="Marketing and Sales" completed={true} />
            <Tab current={true} label="Financial Overview" completed={false} />
            <Tab current={false} label="Strategic Insights" completed={false} />
            <Tab current={false} label="Final Thoughts" completed={false} />
          </div>
        </div>

        <div className="w-full bg-white rounded-2xl shadow-xl p-6 md:p-8 lg:p-10 flex flex-col justify-between h-[80%] sm:h-max sm:gap-y-12">
          {/* Input Section with Fade-in Animation */}
          <div className="flex flex-col items-center h-[50%] sm:h-max justify-between sm:gap-y-12 text-center">
            {/* Heading with Animation */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, ease: "easeOut" }}
              className="flex flex-col gap-y-2"
            >
              <h1 className="text-xl md:text-4xl font-semibold text-black">
                Financial Overview
              </h1>
            </motion.div>

            {/* Input Field with Animation */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, ease: "easeOut", delay: 0.3 }}
              className="w-full max-w-md flex flex-col items-center gap-y-4"
            >
              <label
                htmlFor="funding"
                className="text-base font-medium text-black"
              >
                How much funding do you need to start or scale your business?
              </label>
              <div className="w-full max-w-[400px] relative">
                <input
                  type="text"
                  id="funding"
                  className="w-full border-2 rounded-md py-2 px-3 border-[#FF6633] placeholder:text-[#000000AB] focus:outline-none focus:ring-2 focus:ring-[#FF6633] focus:border-transparent pr-10"
                  value={funding}
                  onChange={(e) => setFunding(e.target.value)}
                  placeholder={flowLanguage === "Spanish"
                    ? "Ingrese la cantidad de financiación"
                    : "Enter your business funding"}
                />
                {/* <button
                  onClick={handleMicClick}
                  className={`absolute top-1/2 right-2 transform -translate-y-1/2 p-1 rounded-full shadow-lg transition duration-300 
                    ${listening ? "bg-red-500 hover:bg-red-600" : "bg-purple-500 hover:bg-purple-600"}`}
                >
                  <MicIcon className="text-white text-xl" />
                </button> */}
              </div>
            </motion.div>
          </div>

          {/* Navigation Buttons */}
          <div className="flex justify-center w-full">
            {showButtomBTN && (
              <motion.div
                initial={{ opacity: 0, y: 30 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, ease: "easeOut", delay: 0.4 }}
                className="flex justify-between lg:max-w-[90%] w-full"
              >
                <button
                  onClick={prevStep}
                  className="border-[#FF6633] border flex items-center gap-x-3 text-black font-medium py-4 px-8 rounded-md notranslate"
                  key={flowLanguage}
                >
                  <svg width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.5 8.99891L9.5 16.3789" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M1.5 9L9.5 1.62" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                  {flowLanguage === "Spanish" ? "ANTERIOR" : "PREVIOUS"}
                </button>

                <button
                  onClick={handleSubmit}
                  className="bg-[#5843BD] flex items-center gap-x-3 text-white font-medium py-3 px-6 rounded-md notranslate"
                  key={flowLanguage}
                >
                  {flowLanguage === "Spanish" ? "GUARDAR" : "SAVE"}
                  <svg width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.5 8.99891L1.5 16.3789" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M9.5 9L1.5 1.62" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </button>
              </motion.div>
            )}
          </div>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
}