import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import Tab from "../components/tab";
import TextEditor from "../components/TextArea";
import { ToastContainer, toast } from "react-toastify";
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";
import MicIcon from "@mui/icons-material/Mic";
import { useDispatch, useSelector } from "react-redux";
import { dispatchBusinessInsight, returnHome } from "../../../Redux/ActionCreator";
import { getOneFromServerCache, saveIntoServerCache } from "../../../utils/saveIntoCache";

export default function BusinessInsight1({ prevStep, nextStep }) {
  const flowLanguage = "English";
  const showButtomBTN = true;
  const [risks, setRisks] = useState("");
  const [isListening, setIsListening] = useState(false);
  const { transcript, listening, resetTranscript, browserSupportsSpeechRecognition } =
    useSpeechRecognition();
  const dispatch = useDispatch();
  const info = useSelector((state) => state.businessInsight);

  // Load initial data from Redux or cache
  useEffect(() => {
    if (info.message !== null && info.message.risks) {
      setRisks(info.message.risks);
    } else {
      let cacheInfo = getOneFromServerCache("businessInsight");
      cacheInfo
        .then((res) => res.json())
        .then((res) => {
          if (res.success === true) {
            if (res.data !== null && res.data !== undefined) {
              let resObj = JSON.parse(res.data);
              dispatch(dispatchBusinessInsight(resObj));
              setRisks(resObj?.risks || "");
            }
          } else if (res.success === false && res.message === "Unauthorized") {
            dispatch(returnHome(res.status));
          }
        })
        .catch((err) => {
          console.error("Error getting business plan info", err);
        });
    }
  }, []);

  // Handle speech recognition transcript
  useEffect(() => {
    if (transcript !== null && transcript !== undefined && transcript !== "") {
      setRisks(transcript); // Update text editor with recognized speech
    }
  }, [transcript]);

  const handleMicClick = () => {
    if (!browserSupportsSpeechRecognition) {
      alert("Your browser does not support speech recognition.");
      return;
    }

    if (listening) {
      SpeechRecognition.stopListening();
    } else {
      resetTranscript(); // Reset transcript before starting new speech
      SpeechRecognition.startListening({ continuous: true, language: flowLanguage === "English" ? "en-US" : "es-ES" });
    }
    setIsListening(!isListening);
  };

  const handleSubmit = () => {
    if (!risks.trim()) {
      toast.error("Please specify your key business risks and mitigation strategies");
      return;
    }

    const businessPlanInsight = {
      risks: risks,
      financial_projections: info.message?.financial_projections || ""
    };

    dispatch(dispatchBusinessInsight(businessPlanInsight));
    saveIntoServerCache("businessInsight", businessPlanInsight);
    nextStep();
  };

  return (
    <div className="bg-[#F7F5FF] min-h-screen flex justify-center p-4 md:p-6 lg:p-8 xl:p-10">
      <div className="flex flex-col gap-y-4 w-full max-w-max">
        {/* Tabs Section */}
        <div className="flex justify-center">
          <div className="flex bg-white rounded-md px-6 py-[0.85rem] gap-x-3 overflow-x-hidden">
            <Tab label="Business Overview" current={false} completed={true} />
            <Tab current={false} label="Market and Competitors" completed={true} />
            <Tab current={false} label="Team and Structure" completed={true} />
            <Tab current={false} label="Marketing and Sales" completed={true} />
            <Tab current={false} label="Financial Overview" completed={true} />
            <Tab current={true} label="Strategic Insights" completed={false} />
            <Tab current={false} label="Final Thoughts" completed={false} />
          </div>
        </div>

        <div className="w-full bg-white rounded-2xl shadow-xl p-6 md:p-8 lg:p-10 flex flex-col justify-between h-[80%] sm:h-max sm:gap-y-12">
          {/* Input Section with Fade-in Animation */}
          <div className="flex flex-col items-center h-[60%] sm:h-max justify-between sm:gap-y-12 text-center">
            {/* Heading with Animation */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, ease: "easeOut" }}
              className="flex flex-col gap-y-2"
            >
              <h1 className="text-xl md:text-4xl font-semibold text-black">
                Strategic Insights
              </h1>
            </motion.div>

            {/* Input Field with Animation */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, ease: "easeOut", delay: 0.3 }}
              className="w-full max-w-2xl flex flex-col items-center gap-y-4"
            >
              <label
                htmlFor="risks"
                className="text-base font-medium text-black"
              >
                What are the key risks in your business, and how will you mitigate them?
              </label>
              <div className="w-full relative">
                <TextEditor
                  key={flowLanguage}
                  onCut={(e) => e.preventDefault()}
                  onCopy={(e) => e.preventDefault()}
                  className="h-56 w-full rounded-lg border border-radius border-indigo-400 border-opacity-30 focus:outline-none focus:border-2 focus:border-indigo-400 placeholder:text-[#B3B3B3] text-4xl font-normal notranslate"
                  style={{ resize: "none" }}
                  placeholder={flowLanguage === "Spanish"
                    ? "Comienza a escribir o usa el micrófono."
                    : "Start typing or use the microphone"}
                  value={risks}
                  type="text"
                  id="risks"
                  name="risks"
                  onChange={(value) => setRisks(value)}
                  required
                  notranslate
                />
                <button
                  onClick={handleMicClick}
                  className={`absolute top-3 right-3 p-1 rounded-full shadow-lg transition duration-300 
                    ${listening ? "bg-red-500 hover:bg-red-600" : "bg-purple-500 hover:bg-purple-600"}`}
                >
                  <MicIcon className="text-white text-xl" />
                </button>
              </div>
            </motion.div>
          </div>

          {/* Navigation Buttons */}
          <div className="flex justify-center w-full">
            {showButtomBTN && (
              <motion.div
                initial={{ opacity: 0, y: 30 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, ease: "easeOut", delay: 0.4 }}
                className="flex justify-between lg:max-w-[90%] w-full"
              >
                <button
                  onClick={prevStep}
                  className="border-[#FF6633] border flex items-center gap-x-3 text-black font-medium py-4 px-8 rounded-md notranslate"
                >
                  <svg width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.5 8.99891L9.5 16.3789" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M1.5 9L9.5 1.62" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                  {flowLanguage === "Spanish" ? "ANTERIOR" : "PREVIOUS"}
                </button>

                <button
                  onClick={handleSubmit}
                  className="bg-[#5843BD] flex items-center gap-x-3 text-white font-medium py-3 px-6 rounded-md notranslate"
                >
                  {flowLanguage === "Spanish" ? "GUARDAR" : "SAVE"}
                  <svg width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.5 8.99891L1.5 16.3789" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M9.5 9L1.5 1.62" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </button>
              </motion.div>
            )}
          </div>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
}