export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGIN_LOADING = "LOGIN_LOADING";
export const LOGOUT_LOADING = "LOGOUT_LOADING";
export const LOG_OUT = "LOG_OUT";
export const SIGNUP_USER = "SIGNUP_USER";
export const SIGNUP_FAILED = "SIGNUP_FAILED";
export const SIGNUP_LOADING = "SIGNUP_LOADING";

// cv prototype
export const CV_LOADING = "CV_LOADING";
export const GENERATING_CV_FAILED = "GENERATING_CV_FAILED";
export const CV_GENERATED_SUCCESSFULLY = "CV_GENERATED_SUCCESSFULLY"

// cv id
export const CV_ID = 'CV_Id';
export const CV_ID_LOADING = "CV_ID_LOADING";
export const CV_PICS = 'CV_PICS';

// user's info
export const USER_NAME_DB = "USER_NAME_DB";
export const LOADING_USER_NAME = "LOADING_USER_NAME"

// Saving Cv details prototype
export const GETTING_CV_LOADING = "GETTING_CV_LOADING"
export const GETTING_CV_FAILED = "GETTING_CV_FAILED";
export const GETTING_CV_SUCCESS = "GETTING_CV_SUCCESS";

// creating user profile
export const GET_PROFILE_LOADING = "GET_PROFILE_LOADING"
export const GET_PROFILE_FAILED = "GET_PROFILE_FAILED";
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";

// creating user profile
export const GET_EMPLOYER_PROFILE_LOADING = "GET_EMPLOYER_PROFILE_LOADING"
export const GET_EMPLOYER_PROFILE_FAILED = "GET_EMPLOYER_PROFILE_FAILED";
export const GET_EMPLOYER_PROFILE_SUCCESS = "GET_EMPLOYER_PROFILE_SUCCESS";

// employee public account view
export const GET_PUBLIC_PROFILE_LOADING = "GET_PUBLIC_PROFILE_LOADING"
export const GET_PUBLIC_PROFILE_FAILED = "GET_PUBLIC_PROFILE_FAILED";
export const GET_PUBLIC_PROFILE_SUCCESS = "GET_PUBLIC_PROFILE_SUCCESS";


// verify mail
export const V_MAIL_LOADING = "V_MAIL_LOADING"
export const V_MAIL_FAILED = "V_MAIL_FAILED"
export const V_MAIL_SUCCESS = "V_MAIL_SUCCESS"

//  reset password
export const R_SET_LOADING = "V_MAIL_LOADING"
export const R_SET_FAILED = "R_SET_FAILED"
export const R_SET_SUCCESS = "R_SET_SUCCESS"

export const JOBTITLE = "JOBTITLE";
export const JOBTITLE_LOADING = "JOBTITLE_LOADING";

export const SKILLS = "SKILLS";
export const SKILLS_LOADING = "SKILLS_LOADING";

export const PERSONAL_SKILLS = "PERSONAL_SKILLS";
export const PERSONAL_SKILLS_LOADING = "PERSONAL_SKILLS_LOADING";

export const JOBDETAILS = "JOBDETAILS";
export const JOBDETAILS_LOADING = "JOBDETAILS_LOADING";

export const JOBDESCRIPTION = "JOBDESCRIPTION";
export const JOBDESCRIPTION_LOADING = "JOBDESCRIPTION_LOADING";

export const LANGUAGE = "LANGUAGE";
export const LANGUAGE_LOADING = "LANGUAGE_LOADING";

export const LANGUAGE_TRANSLATION = "LANGUAGE_TRANSLATION";
export const LANGUAGE_TRANSLATION_LOADING = "LANGUAGE_TRANSLATION_LOADING";

export const LANG = "LANG";
export const LANG_LOADING = "LANG_LOADING";

export const SPANISH = "SPANISH";
export const SPANISH_LOADING = "SPANISH_LOADING";

export const ABOUT = "ABOUT";
export const ABOUT_LOADING = "ABOUT_LOADING";
export const ABOUT_FAILED = "ABOUT_FAILED";

export const LANG_TYPE = "LANG_TYPE";
export const LANG_TYPE_S = "LANG_TYPE_S";

export const LAST_RESUME = "LAST_RESUME";
export const LAST_RESUME_LOADING = "LAST_RESUME_LOADING";

export const SECRET = "SECRET";
export const SECRET_LOADING = "SECRET_LOADING";

export const SAVE_LINK = "SAVE_LINK"
export const SAVE_LINK_LOADING = "SAVE_LINK_LOADING"

export const JOB = "JOB";
export const JOB_LOADING = "JOB_LOADING";
export const JOB_FAILED = "JOB_FAILED";

export const JOBS_Doc = "JOBS_Doc";
export const JOBS_Doc_FAILED = "JOBS_Doc_FAILED";
export const JOBS_Doc_LOADING = "JOBS_Doc_LOADING";


export const POSTING_ERR = "POSTING_ERR";
export const POSTING_SUCCESS = "POSTING_SUCCESS";
export const POSTING_LOADING = "POSTING_LOADING";

export const JOB_POSTING_DETAILS = "JOB_POSTING_DETAILS";
export const JOB_POSTING_DETAILS_LOADING = "JOB_POSTING_DETAILS_LOADING"

export const JOBS_POSTED = "JOBS_POSTED";
export const JOBS_POSTED_FAILED = "JOBS_POSTED_FAILED";
export const JOBS_POSTED_LOADING = "JOBS_POSTED_LOADING";

export const JOB_TO_UPDATE = "JOB_TO_UPDATE";
export const JOB_TO_UPDATE_LOADING = "JOB_TO_UPDATE_LOADING";

export const JOB_APPLICANTS = "JOB_APPLICANTS";
export const JOB_APPLICANTS_LOADING = "JOB_APPLICANTS_LOADING";
export const JOB_APPLICANTS_FAILED = "JOB_APPLICANTS_FAILED";

export const RECOMMENDED_CANDIDATE = "RECOMMENDED_CANDIDATE";
export const RECOMMENDED_CANDIDATE_LOADING = "RECOMMENDED_CANDIDATE_LOADING";
export const RECOMMENDED_CANDIDATE_FAILED = "RECOMMENDED_CANDIDATE_FAILED";

export const CHAT_INFO = "CHAT_INFO";
export const CHAT_INFO_LOADING = "CHAT_INFO_LOADING";
export const CHAT_INFO_FAILED = "CHAT_INFO_FAILED";

export const END_TO_END_CHAT = "END_TO_END_CHAT";
export const END_TO_END_CHAT_LOADING = "END_TO_END_CHAT_LOADING";
export const END_TO_END_CHAT_FAILED = "END_TO_END_CHAT_FAILED";

export const SEND_MESSAGE = "SEND_MESSAGE";
export const SEND_MESSAGE_LOADING = "SEND_MESSAGE_LOADING";
export const SEND_MESSAGE_FAILED = "SEND_MESSAGE_FAILED";

export const SAVE_JOB = "SAVE_JOB";
export const SAVE_JOB_LOADING = "SAVE_JOB_LOADING";
export const SAVE_JOB_FAILED = "SAVE_JOB_FAILED";

export const GET_SAVED_JOBS = "GET_SAVED_JOBS";
export const GET_SAVED_JOBS_LOADING = "GET_SAVED_JOBS_LOADING";
export const GET_SAVED_JOBS_FAILED = "GET_SAVED_JOBS_FAILED";


export const DECISION_CARD = "DECISION_CARD";
export const DECISION_CARD_LOADING = "DECISION_CARD_LOADING";
export const DECISION_CARD_FAILED = "DECISION_CARD_FAILED";


export const NOTIFICATION = "NOTIFICATION";
export const NOTIFICATION_LOADING = "NOTIFICATION_LOADING";

export const MESSAGE_NOTIFICATION = "MESSAGE_NOTIFICATION";
export const MESSAGE_NOTIFICATION_LOADING = "MESSAGE_NOTIFICATION_LOADING";


export const PHONE_VERIFICATION = "PHONE_VERIFICATION";
export const PHONE_VERIFICATION_LOADING = "PHONE_VERIFICATION_LOADING";
export const PHONE_VERIFICATION_FAILED = "PHONE_VERIFICATION_FAILED";

export const SEND_OTP = "SEND_OTP";
export const SEND_OTP_LOADING = "SEND_OTP_LOADING";
export const SEND_OTP_FAILED = "SEND_OTP_FAILED";

export const TOTAL_PAGE = "TOTAL_PAGE";
export const TOTAL_PAGE_LOADING = "TOTAL_PAGE_LOADING";

export const GET_A_JOB_LOADING = "GET_A_JOB_LOADING";
export const GET_A_JOB_FAILED = "GET_A_JOB_FAILED";
export const GET_A_JOB = "GET_A_JOB";

// last page visited
export const GET_LAST_PAGE_VISITED = "GET_LAST_PAGE_VISITED";
export const GET_LAST_PAGE_VISITED_LOADING = "GET_LAST_PAGE_VISITED_LOADING";
export const GET_LAST_PAGE_VISITED_ERROR = "GET_LAST_PAGE_VISITED_ERROR"


export const COUNT = "COUNT";
export const COUNT_LOADING = "COUNT_LOADING";

export const ERROR = "ERROR";
export const ERROR_LOADING = "ERROR_LOADING"

export const CLICK = "CLICK";
export const CLICK_LOADING = "CLICK_LOADING"

export const PUBLIC_RESUME = "PUBLIC_RESUME";
export const PUBLIC_RESUME_LOADING = "PUBLIC_RESUME_LOADING";
export const PUBLIC_RESUME_FAILED = "PUBLIC_RESUME_FAILED";

export const NEW_JOB_POST_LOADING = "NEW_JOB_POST_LOADING";
export const NEW_JOB_POST_SUCCESSFUL = "NEW_JOB_POST_SUCCESSFUL";
export const NEW_JOB_POST_FAILED = "NEW_JOB_POST_FAILED";

export const CREATE_REFERRAL_LINK_LOADING = 'CREATE_REFERRAL_LINK_LOADING';
export const CREATE_REFERRAL_LINK_SUCCESS = 'CREATE_REFERRAL_LINK_SUCCESS';
export const CREATE_REFERRAL_LINK_FAILED = 'CREATE_REFERRAL_LINK_FAILED';

export const REFERRER_LINK = "REFERRER_LINK";
export const REFERRER_LINK_LOADING = "REFERRER_LINK_LOADING";

export const EMPLOYER_EMPLOYEEINFO = "EMPLOYER_EMPLOYEEINFO";
export const EMPLOYER_EMPLOYEEINFO_LOADING = "EMPLOYER_EMPLOYEEINFO_LOADING";

export const CANDIDATE_CATEGORY = "CANDIDATE_CATEGORY";
export const CANDIDATE_CATEGORY_LOADING = "CANDIDATE_CATEGORY_LOADING";

export const CANDIDATE_SKILL_MATCH = "CANDIDATE_SKILL_MATCH";
export const CANDIDATE_SKILL_MATCH_LOADING = "CANDIDATE_SKILL_MATCH_LOADING";

export const CANDIDATE_TYPE = "CANDIDATE_TYPE";
export const CANDIDATE_TYPE_LOADING = "CANDIDATE_TYPE_LOADING";

export const SOCKET = "SOCKET";
export const SOCKET_LOADING = "SOCKET_LOADING";

export const APPLICATION_PUBLIC_LOADING = "APPLICATION_PUBLIC_LOADING";
export const APPLICATION_PUBLIC_SUCCESSFUL = "APPLICATION_PUBLIC_SUCCESSFUL";
export const APPLICATION_PUBLIC_FAILED = "APPLICATION_PUBLIC_FAILED";

export const ONLINE = "ONLINE";
export const ONLINE_LOADING = "ONLINE_LOADING";

export const INDIVIDUAL_JOB_CANDIDATE_LOADING = "INDIVIDUAL_JOB_CANDIDATE_LOADING";
export const INDIVIDUAL_JOB_CANDIDATE_SUCCESSFUL = "INDIVIDUAL_JOB_CANDIDATE_SUCCESSFUL";
export const INDIVIDUAL_JOB_CANDIDATE_FAILED = "INDIVIDUAL_JOB_CANDIDATE_FAILED";

export const SIDEBAR = "SIDEBAR";

export const ANALYTICS = "ANALYTICS";
export const ANALYTICS_LOADING = "ANALYTICS_LOADING";

export const ANALYTICS_WEEKLY = "ANALYTICS_WEEKLY";
export const ANALYTICS_LOADING_WEEKLY = "ANALYTICS_LOADING_WEEKLY";

export const BUSINESS_OVERVIEW_PLAN = "BUSINESS_OVERVIEW_PLAN";
export const BUSINESS_OVERVIEW_PLAN_ERROR = "BUSINESS_OVERVIEW_PLAN_ERROR"; 
export const BUSINESS_OVERVIEW_PLAN_LOADING = "BUSINESS_OVERVIEW_PLAN_LOADING";

export const BUSINESS_MARKET_COMPETITORS = "BUSINESS_MARKET_COMPETITORS";
export const BUSINESS_MARKET_COMPETITORS_ERROR = "BUSINESS_MARKET_COMPETITORS_ERROR";
export const BUSINESS_MARKET_COMPETITORS_LOADING = "BUSINESS_MARKET_COMPETITORS_LOADING";

export const BUSINESS_COMPETITORS = "BUSINESS_COMPETITORS";
export const BUSINESS_COMPETITORS_ERROR = "BUSINESS_COMPETITORS_ERROR";
export const BUSINESS_COMPETITORS_LOADING = "BUSINESS_COMPETITORS_LOADING";

export const BUSINESS_INSIGHT = "BUSINESS_INSIGHT";
export const BUSINESS_INSIGHT_ERROR = "BUSINESS_INSIGHT_ERROR";
export const BUSINESS_INSIGHT_LOADING = "BUSINESS_INSIGHT_LOADING";

export const BUSINESS_FINANCIALS = "BUSINESS_FINANCIALS";
export const BUSINESS_FINANCIALS_ERROR = "BUSINESS_FINANCIALS_ERROR";
export const BUSINESS_FINANCIALS_LOADING = "BUSINESS_FINANCIALS_LOADING";

export const BUSINESS_STRUCTURE = "BUSINESS_STRUCTURE";
export const BUSINESS_STRUCTURE_ERROR = "BUSINESS_STRUCTURE_ERROR";
export const BUSINESS_STRUCTURE_LOADING = "BUSINESS_STRUCTURE_LOADING";

export const BUSINESS_MARKETING = "BUSINESS_MARKETING";
export const BUSINESS_MARKETING_ERROR = "BUSINESS_MARKETING_ERROR";
export const BUSINESS_MARKETING_LOADING = "BUSINESS_MARKETING_LOADING";

export const BUSINESS_FINAL_THOUGHT = "BUSINESS_FINAL_THOUGHT";
export const BUSINESS_FINAL_THOUGHT_ERROR = "BUSINESS_FINAL_THOUGHT_ERROR";
export const BUSINESS_FINAL_THOUGHT_LOADING = "BUSINESS_FINAL_THOUGHT_LOADING";

export const SAVE_BUSINESS_PLAN = "SAVE_BUSINESS_PLAN";
export const SAVE_BUSINESS_PLAN_LOADING = "SAVE_BUSINESS_PLAN_LOADING";


export const BUSINESS_PLAN_LOADING = "BUSINESS_PLAN_LOADING";
export const BUSINESS_PLAN_FAILED = "BUSINESS_PLAN_FAILED";
export const BUSINESS_PLAN_SUCCESS = "BUSINESS_PLAN_SUCCESS";