import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import { getUserBusinessPlans } from '../../../Redux/ActionCreator';

const BusinessPlanPreview = () => {
  const dispatch = useDispatch();
  const { loading, message, error } = useSelector((state) => state.business_plans || {});
  const businessPlansData = message?.data || [];
  const { planId } = useParams();

  // Fetch plans if not already loaded
  useEffect(() => {
    if (!businessPlansData.length) {
      dispatch(getUserBusinessPlans());
    }
  }, [dispatch, businessPlansData.length]);

  const selectedPlan = businessPlansData.find(plan => plan._id === planId) || {};

  // Structure the sections based on the actual plan data
  const sections = [
    {
      id: 'business-overview',
      title: 'Business Overview',
      subsections: [
        { title: 'Business Name', content: selectedPlan.businessName },
        { title: 'Products or Services', content: selectedPlan.productsOrServices },
        { title: 'Uniqueness', content: selectedPlan.uniqueness },
        { title: 'Problem Solved', content: selectedPlan.problemSolved },
      ]
    },
    {
      id: 'market-and-competitors',
      title: 'Market and Competitors',
      subsections: [
        { title: 'Market Size', content: selectedPlan.marketSize },
        {
          title: 'Major Competitors',
          content: Array.isArray(selectedPlan.competitors) ? selectedPlan.competitors : []
        },
        { title: 'Competitive Advantage', content: selectedPlan.competitiveStandOut },
        { title: 'Industry Trends', content: selectedPlan.industryTrends },
        { title: 'Market Share', content: selectedPlan.marketShare },
      ]
    },
    {
      id: 'team-and-structure',
      title: 'Team and Structure',
      subsections: [
        { title: 'Legal Structure', content: selectedPlan.legalStructure },
        { title: 'Team Members and Roles', content: selectedPlan.memberAndRoles },
        { title: 'Hiring Plans', content: selectedPlan.planToHire },
      ]
    },
    {
      id: 'marketing-and-sales',
      title: 'Marketing and Sales',
      subsections: [
        { title: 'Marketing Strategies', content: selectedPlan.marketingStrategies },
        { title: 'Customer Relationships', content: selectedPlan.buildRelationShip },
        { title: 'Sales Strategy', content: selectedPlan.salesStrategy },
        { title: 'Customer Acquisition Cost', content: selectedPlan.customerAcquisitionCost },
        { title: 'Conversion Rate', content: selectedPlan.conversionRate },
      ]
    },
    {
      id: 'financial-overview',
      title: 'Financial Overview',
      subsections: [
        { title: 'Expenses', content: selectedPlan.expenses },
        { title: 'Funding Required', content: selectedPlan.fundingRequired },
        { title: 'Revenue Streams', content: selectedPlan.revenueStreams },
        { title: 'Pricing Strategy', content: selectedPlan.pricingStrategy },
        { title: 'Use of Funds', content: selectedPlan.useFund },
        { title: 'Financial Projections', content: selectedPlan.financialProjection },
      ]
    },
    {
      id: 'strategic-insights',
      title: 'Strategic Insights',
      subsections: [
        { title: 'Risk Factors', content: selectedPlan.risk },
      ]
    },
    {
      id: 'additional-information',
      title: 'Additional Information',
      subsections: [
        { title: 'Additional Info', content: selectedPlan.additionalInfo },
      ]
    }
  ];

  // Handle loading and error states
  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen ">
        <p>Loading business plan...</p>
      </div>
    );
  }

  if (error || !selectedPlan._id) {
    return (
      <div className="flex justify-center items-center h-screen ">
        <p className="text-red-500">
          {error || 'Business plan not found'}
        </p>
      </div>
    );
  }

  return (
    <div className="flex justify-center  overflow-y-scroll pt-8 md:px-6">
      <div className=" min-h-screen pb-16 w-full max-w-5xl mx-auto">
        {/* Header with breadcrumb */}
        <div className="py-3 px-4">
          <Link to="/employer/business-plan" className="text-sm text-gray-600">
            {`Business plans > ${selectedPlan.businessName || 'Plan Preview'}`}
          </Link>
        </div>

        <div className="px-4 py-8">
          {/* Title section */}
          <div className="text-center mb-8">
            <h1 className="text-xl font-semibold text-[#5843BD] mb-2">
              Here is a preview of your business plan.
            </h1>
            <p className="text-[#000000] font-medium">
              Click on the edit buttons to update any section.
            </p>
          </div>

          {/* Business plan content */}
          <div className=" flex flex-col gap-y-4">
            {sections.map((section) => (
              <div key={section.id} className="bg-white rounded-md p-6">
                <div className="flex bg-white border-b border-[#777DEA26] justify-between items-center pb-6">
                  <p className="text-[#5843BD] font-semibold">{section.title}</p>
                  <button className="bg-[#5843BD] text-white px-3 py-1 rounded text-sm">
                    Edit
                  </button>
                </div>

                <div className="">
                  {section.subsections.map((subsection, subIndex) => (
                    <div key={`${section.id}-${subIndex}`} className="border-[#777DEA26] border-b py-6">
                      <h3 className="text-[#00000080] leading-[1.5]">{subsection.title}</h3>
                      {Array.isArray(subsection.content) ? (
                        <div className="text-[#000000]">
                          {subsection.content.map((item, itemIndex) => (
                            <p
                              key={`${section.id}-${subIndex}-${itemIndex}`}
                              className="mb-1 font-medium"
                              dangerouslySetInnerHTML={{ __html: item }}
                            />
                          ))}
                        </div>
                      ) : (
                        <p
                          className="text-[#000000]"
                          dangerouslySetInnerHTML={{ __html: subsection.content || 'Not provided' }}
                        />
                      )}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>

          {/* Navigation buttons */}
          <div className="flex justify-between mt-8">
            <button className="border border-[#FF6633] bg-white text-[#00000080] px-8 py-4 rounded-lg flex items-center gap-2 text-base font-medium">
              <svg width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.5 8.99891L9.5 16.3789" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M1.5 9L9.5 1.62" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>

              PREVIOUS
            </button>

            <button className="bg-[#5843BD] text-white  px-8 py-4 rounded-lg flex items-center gap-2 font-medium text-base">
              COMPLETE
              <svg width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.5 8.99891L1.5 16.3789" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M9.5 9L1.5 1.62" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>

            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessPlanPreview;