import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import Tab from "../components/tab";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify"; // Assuming you're using react-toastify for notifications
import { dispatchBusinessPlanOverview, returnHome } from "../../../Redux/ActionCreator";
import { getOneFromServerCache, saveIntoServerCache } from "../../../utils/saveIntoCache";

export default function BusinessOverview1({ prevStep, nextStep }) {
  // Placeholder state for inputs (you can replace these with your actual state management)
  const flowLanguage = "English"; // Example: Can be "Spanish" or "English"
  const showButtomBTN = true; // Control visibility of buttons
  const [name, setName] = useState("");
  const dispatch = useDispatch();
  const info = useSelector((state) => state.businessPlanOverview);

  useEffect(() => {
    if (info.message !== null && info.message.business_name) {
      setName(info.message.business_name);
    } else {
      let cacheInfo = getOneFromServerCache("businessPlanOverview");
      cacheInfo
        .then((res) => res.json())
        .then((res) => {
          if (res.success === true) {
            if (res.data !== null && res.data !== undefined) {
              let resObj = JSON.parse(res.data);
              dispatch(dispatchBusinessPlanOverview(resObj));
              setName(resObj?.business_name || "");
            }
          } else if (res.success === false && res.message === "Unauthorized") {
            dispatch(returnHome(res.status));
          }
        })
        .catch((err) => {
          console.error("Error getting business plan info", err);
        });
    }
  }, []);

  const handleSubmit = () => {
    if (!name.trim()) {
      // Show error if name is empty
      toast.error("Please enter a business name");
      return;
    }

    const businessPlanData = {
      business_name: name,
      products_services: info.message?.products_services ? info.message.products_services : '',
      mission: info.message?.mission ? info.message.mission : '',
      problem_solved: info.message?.problem_solved ? info.message.problem_solved : '',
      target_customers: info.message?.target_customers ? info.message.target_customers : '',
      unique_selling_points: info.message?.unique_selling_points ? info.message.unique_selling_points : '',
    };

    // Save to Redux and cache
    dispatch(dispatchBusinessPlanOverview(businessPlanData));
    saveIntoServerCache("businessPlanOverview", businessPlanData);
    nextStep();
  };

  return (
    <div className="bg-[#F7F5FF] min-h-screen flex justify-center p-4 md:p-6 lg:p-8 xl:p-10">

      <div className="flex flex-col gap-y-4 w-full max-w-max">
        {/* Tabs Section */}
        <div className="flex justify-center">
          <div className="flex bg-white rounded-md px-6 py-[0.85rem] gap-x-3 overflow-x-hidden">
            <Tab label="Business Overview" current={true} completed={false} />
            <Tab current={false} label="Market and Competitors" completed={false} />
            <Tab current={false} label="Team and Structure" completed={false} />
            <Tab current={false} label="Marketing and Sales" completed={false} />
            <Tab current={false} label="Financial Overview" completed={false} />
            <Tab current={false} label="Strategic Insights" completed={false} />
            <Tab current={false} label="Final Thoughts" completed={false} />
          </div>
        </div>

        <div className="w-full bg-white rounded-2xl shadow-xl p-6 md:p-8 lg:p-10 flex flex-col justify-between h-[80%] sm:h-max sm:gap-y-12">

          {/* Input Section with Fade-in Animation */}
          <div className="flex flex-col items-center h-[50%] sm:h-max justify-between sm:gap-y-12 text-center">
            {/* Heading with Animation */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, ease: "easeOut" }}
              className="flex flex-col gap-y-2"
            >
              <h1 className="text-xl md:text-4xl font-semibold text-black">
                Business Overview
              </h1>
              <p className="text-base font-medium md:text-2xl text-[#5843BD]">
                Let's begin by understanding your business.<br className="hidden md:block" />
                This is your time to tell me about your big idea!
              </p>
            </motion.div>

            {/* Input Field with Animation */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, ease: "easeOut", delay: 0.3 }}
              className="w-full max-w-md flex flex-col items-center gap-y-4"
            >
              <label
                htmlFor="businessName"
                className="text-base font-medium text-black"
              >
                What is the name of your business?
              </label>
              <input
                type="text"
                id="businessName"
                className="w-full border-2 max-w-[400px] rounded-md py-2 px-3 border-[#FF6633] placeholder:text-[#000000AB] focus:outline-none focus:ring-2 focus:ring-[#FF6633] focus:border-transparent"
                value={name}
                onChange={(e) => {
                  setName(e.target.value)
                }}
                placeholder="Enter your business name"
              />
            </motion.div>
          </div>

          {/* Navigation Buttons */}
          <div className="flex justify-center w-full">
            {showButtomBTN && (
              <motion.div
                initial={{ opacity: 0, y: 30 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, ease: "easeOut", delay: 0.4 }}
                className="flex justify-between lg:max-w-[90%] w-full">
                <button
                  onClick={prevStep}
                  className="border-[#FF6633] border flex items-center gap-x-3 text-black font-medium py-4 px-8 rounded-md notranslate" key={flowLanguage}
                >
                  <svg width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.5 8.99891L9.5 16.3789" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M1.5 9L9.5 1.62" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                  {flowLanguage == "Spanish" ? (
                    "ANTERIOR"
                  ) : (
                    "PREVIOUS"
                  )}
                </button>

                <button
                  onClick={handleSubmit}
                  className="bg-[#5843BD] flex items-center gap-x-3 text-white font-medium py-3 px-6 rounded-md notranslate" key={flowLanguage}
                >
                  {flowLanguage == "Spanish" ? (
                    "GUARDAR"
                  ) : (
                    "SAVE"
                  )}
                  <svg width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.5 8.99891L1.5 16.3789" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M9.5 9L1.5 1.62" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </button>

              </motion.div>
            )}
          </div>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
}