import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
// require dispatch from react-redux;
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { createPortal } from "react-dom";
import SendMailModal from "../common/sentMailModal/sentMailModal";
import HomeButton from "../common/HomeButton/homeButton";
// import the signup function from the redux actioncreator file.
import {
  signUpFunction,
  officialGoogleAuth,
  signup_loading
} from "../../Redux/ActionCreator";
import HelmetProvide from "../common/helmet";
import SignupForm from "../common/signup_form";

const HireRegister = () => {
  const navigate = useNavigate();
  const [hide, setHide] = useState(true);
  const [hide1, setHide1] = useState(true);
  
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  let flow = queryParams.get("flowId");

  const [userCred, setUserCred] = useState({
    username: "",
    password: "",
    confirmPassword: "",
    AcctType: "Official",
    code: queryParams.get('ref'),
    flow
  });
  const dispatch = useDispatch();
  const signup_status = useSelector((stat) => stat.signup);
  localStorage.setItem("subflow", flow);
  // console.log("userCred", userCred);

  useEffect(() => {
    if (queryParams.get('ref') !== null) {
      localStorage.clear();
      localStorage.setItem("ref", queryParams.get('ref'));
    } else {
      let ref = localStorage.getItem("ref");
      localStorage.clear();
      localStorage.setItem("ref", ref);
    }
    localStorage.setItem("subflow", flow);
  }, []);

  const [ show, setShow ] =  useState(false);
  useEffect(() => {
    if (signup_status.errMess !== null) {
      setTimeout(() => {
        toast.error(signup_status.errMess, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }, 50);

      dispatch(signup_loading());
    }
    if (signup_status.message !== null) {
      // setTimeout(() => {
      //   toast.success(signup_status.message?.status, {
      //     position: toast.POSITION.TOP_RIGHT,
      //   });
      // }, 50);
      setShow(true);

      dispatch(signup_loading());
    }
  }, [signup_status.errMess, signup_status.message]);

  const handleSubmit = () => {
    dispatch(signUpFunction(userCred));
    localStorage.setItem("username", userCred?.username);
    localStorage.removeItem("ref");
    setUserCred((prev) => ({
      ...prev,
      username: "",
      password: "",
      confirmPassword: "",
      AcctType: "Official",
      code: "",
      flow: "",
    }));
  };

  const screenheight = window.innerHeight;

  const heighting = screenheight >= 755 ? "md:top-[50%] md:translate-y-[-50%]" : "md:top-[35px]";

 // In your component:
 const showModal = show === true ? (
  (() => {
    const username = localStorage.getItem("username");
    localStorage.removeItem("username");
    return username.includes('@') ? 
      createPortal(
        <div className="top-0 left-0 fixed bottom-0 right-0 z-10 h-screen flex justify-center items-center bg-black bg-opacity-70 backdrop-blur-[2px]">
          <SendMailModal signup={true}/>
        </div>, 
        document.getElementById("mail")
      ) : (
        toast.success("Phone number signup successful! Redirecting to login...", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          onClose: () => window.location.href = '/login'
        }) && null
      );
  })()
) : null;

  return (
    <>
      <HelmetProvide
        title="Moil | Talent Recruiting Platform"
        description="Moil is your partner in finding the best blue-collar talent. We provide tools to help workers showcase their skills and connect them with recruiters on our platform."
      />

      <div className="h-screen relative max-w-[100vw] overflow-x-hidden">
        <div className={`absolute z-10 ${heighting} w-full gap-x-4 gap-y-4 justify-center lg:max-w-[876px] xl:max-w-[1024px] left-[50%] translate-x-[-50%] top-[80px] flex lg:gap-x-0 lg:justify-between flex-col md:flex-row items-center px-4`}>
         <div className="w-full" >
            <HomeButton className="bg-white p-4 vsm:mb-5 md:mt-0" path={`https://moilapp.com/business`} text="Homepage" />
            <div className="md:flex md:items-center md:justify-between w-full">
              <p className="text-white font-poppins text-[24px] md:text-[32px] font-bold leading-[30px] md:leading-[36px] tracking-normal flex flex-col gap-y-10 max-w-[380px]">AI-powered job matching
                and hiring for the blue collar
                industry.
                <span className="font-poppins text-[14px] md:text-[24px] font-[500] leading-[18px] md:leading-[21px] tracking-normal vsm:mb-4 md:mb-0"> Sign up now to experience it.
                </span>
              </p>
              <SignupForm type="employer" googleSignup={() => dispatch(officialGoogleAuth())} username={userCred.username} password={userCred.password} confirmPassword={userCred.confirmPassword} setUserCred={setUserCred} handleSubmit={handleSubmit} />
            </div>
         </div>
        </div>


        <div className={`w-full h-added-25 ${screenheight > 755 ? "md:h-full " : "md:h-added-35"} grid custom-grid`}>

          <img src="https://res.cloudinary.com/drlcisipo/image/upload/v1709257426/Website%20images/signup-business_etpphn.jpg" className="w-full  md:max-h-max md:h-full" />  {/* max-h-[340px] */}


          <div className="relative h-full overflow-hidden -z-1000">
            <svg width="851" height="851" viewBox="0 0 1028 992" fill="none" xmlns="http://www.w3.org/2000/svg" className="absolute top-[-30%] right-[-30%] -z-10">
              <g opacity="0.1" filter="url(#filter0_f_3436_5351)">
                <circle cx="739.5" cy="252.5" r="425.5" fill="#777DEA" />
              </g>
              <defs>
                <filter id="filter0_f_3436_5351" x="0" y="-487" width="1479" height="1479" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                  <feGaussianBlur stdDeviation="157" result="effect1_foregroundBlur_3436_5351" />
                </filter>
              </defs>
            </svg>

            <svg width="1065" height="1065" viewBox="0 0 1201 1024" fill="none" xmlns="http://www.w3.org/2000/svg" className="absolute bottom-[-25%] right-[-20%] -z-10">
              <g opacity="0.1" filter="url(#filter0_f_3436_5429)">
                <circle cx="847.5" cy="695.5" r="533.5" fill="#FF6633" />
              </g>
              <defs>
                <filter id="filter0_f_3436_5429" x="0" y="-152" width="1695" height="1695" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                  <feGaussianBlur stdDeviation="157" result="effect1_foregroundBlur_3436_5429" />
                </filter>
              </defs>
            </svg>
          </div>
        </div>
      </div>
      <ToastContainer />
      {showModal}
    </>
  );
};

export default HireRegister;
