import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // import styles

const TextEditor = (props) => {
//   const [value, setValue] = useState('');

  const modules = {
    toolbar: [
    //   [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
    //   [{size: []}],
      ['bold', 'italic',],  // 'underline', 'strike', 'blockquote'
      [{'list': 'bullet'}], // {'list': 'ordered'}, 
    //   ['link', 'image', 'video'],
    //   ['clean']
    ],
  };

  const formats = [
    // 'header', 'font', 'size',
    'bold', 'italic', // 'underline', 'strike', 'blockquote',
    'bullet', 'list',
    // 'link', 'image', 'video'
  ];

  const handleChange = (content, delta, source, editor) => {
    console.log("content", content);
    console.log("delta", delta);
    console.log("source", source);
    console.log("editor", editor);
    setValue(content);
  }

  return (
    // <div className="vsm:w-full">
      <ReactQuill
        value={props.value} // props.value
        onChange={props.onChange} //props.onChangeHandler
        modules={modules}
        formats={formats}
        name={props.name}
        theme="snow"
        // style={{
        //     width: "100%",
        //     resize: "none",
        //     // padding: "16px",
        //     borderColor: "#777dea54",
        //     borderWidth: "1px",
        //     height: "14rem",
        //     outlineColor: "#777dea54",
        //     borderRadius: "8px",
        //     "&:focus": {
        //       borderColor: "#777dea54", // Border color on focus
        //     },
        //   }}
        onKeyDown={props.onKeyDown}
        onCopy={props.onCopy}
        onCut={props.onCut}
        id={props.id}
        placeholder={props.placeholder}
        className={props.className}
        required
        notranslate
      />
    // </div>
  );
};

export default TextEditor;
