import { motion } from "framer-motion";
import { useSelector, useDispatch } from "react-redux";

export default function BusinessPlanWelcome({ nextStep }) {

  return (
    <>
      <motion.div
        initial={{ opacity: 0, y: 100 }} // Start faded out and lower
        animate={{ opacity: 1, y: 0 }} // Fade in and move up
        transition={{ duration: 1.2, ease: "easeOut" }} // Smooth transition
        className="bg-[#FAFAFA] px-3 lg:pl-6 py-8 w-full overflow-y-scroll ScrollOnly h-calc-70 flex justify-center"
      >
        <div className="w-full xtraxl:max-w-calculate flex flex-col items-center">
          <div className="max-w-[1000px] rounded-2xl py-8 h-full xtraxl:max-w-calculate py-5 w-full bg-white bg-opacity-80 flex-col justify-start items-start gap-5 inline-flex">
            <div className="bg-white h-full mx-auto flex flex-col gap-y-8 items-center justify-center px-4 md:px-8 lg:px-12 xl:px-16 text-center">
              <h1 className="text-2xl md:text-4xl font-semibold">
                Welcome! 🎉
              </h1>
              <p className="text-base max-w-[650px] text-black font-medium md:text-xl mb-8">
                You're about to create a powerful business plan for your company. Don't worry – I'll guide you through the process step-by-step, making it simple and straightforward. By the end of this, you’ll have a detailed, AI-powered business plan, tailored specifically for your business and based on the latest market trends and data. Ready to start?
              </p>
              <p className="text-black mb-8">Click 'Start' to begin.</p>
              <button
                onClick={nextStep}
                className="bg-gradient-to-r max-w-max from-[#FF6633] to-[#5843BD] text-white font-medium py-5 px-[120px] rounded-lg shadow-md hover:from-[#5843BD] hover:to-[#FF6633] transition-colors duration-300"
              >
                START
              </button>
            </div>
          </div>
        </div>
      </motion.div>
    </>
  );
}
