import React from "react";

export default function Tab(props) {
  const { label, current, completed } = props;

  return (
    <div
      className={`flex items-center gap-x-2 rounded-md p-2 transition-all duration-300 ${
        current
          ? "bg-[#5843BD] text-white"
          : completed
          ? "bg-[#5843BD] text-white"
          : "bg-[#5843BD1A] text-[#5843BD]"
      }`}
    >
      <p className="text-xs whitespace-nowrap">{label}</p>
    </div>
  );
}