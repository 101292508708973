import React, { useState, useEffect, lazy, Suspense } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import TopBar from "./components/topbar/Top-Bar";
import Sidebar from "./components/sidebar/Side-Bar";
import PhoneVerification from "./components/authPage/phone.jsx"
import HireLogin from "./components/hireLogin";
import HireRegister from "./components/hireRegister";

// import mypdf from it's file
import StripeComponent from "./components/subscription/StripeContainer";
import "react-toastify/dist/ReactToastify.css";
// protected route component
import ProtectedRoute from "./middleware/Auth/protectedRoute";
import LoaderModal from "./components/common/LoaderModal";
import Logout from "./components/Logout/logout";
import { useSelector, useDispatch } from "react-redux";
// imports from BigSam
import EmployerProfile from "./components/Profile/index.jsx";
import EmployerForm from "./components/EmployerForm/EmployerForm";
import EmployerProfilePhoto from "./components/Image";
import UploadDoc from "./components/upload_doc/index.jsx";

import ProfileProtected from "./middleware/Auth/profileProtected";
import EmployerProfileView from "./components/Profile/Employee/worker_employerView_profile"
import AuthPage from "./components/authPage";
import Forgot from "./components/authPage/Forgot.jsx";
import Reset from "./components/authPage/Reset.jsx";
import EmployerHomeDashboard from "./views/layout";
import JobPostPreviewDetails from "./components/Job/jobPosting/preview.jsx";
import JobPosting from "./components/Job/jobPosting";
import EmployerDashboard from "./components/Dashboard/index.jsx";
import EmployerJobs from "./components/Job/index.jsx";
import JobsCandidate from "./components/Job/jobs_candidate";
import ApplicationDetails from "./components/Job/application_details.jsx"
import EmployerInboxUser from "./components/Message";
import EmployerInbox from "./components/Message/inbox";
import HomeTopBarOnlyDashboard from "./views/layout/top_only.jsx";
import EmployerDecisionCardRedesign from "./components/DecisionCard/decisionCardRedesign .jsx"
import SearchJobField from "./components/common/SearchBars/SearchJobField";
import PageTrackingHOC from "./middleware/Auth/pageTrackingHOC";
import UserPreviewDetails from "./components/Resume/moil_user_preview.jsx"
import { AnalyticsProviderContext } from "./context/AnalyticsContext";
import Analytics from 'analytics';
import segmentPlugin from '@analytics/segment';
import JobMatch from "./components/Job/job_match";
import RecruiterDashboard from "./components/Dashboard/moil_recruiter_dashboard.jsx"
import NotFoundPage from "./components/error_page/index.jsx";
import NotAvailable from "./components/common/not_available";
import TextEditor from "./components/common/textarea/NewTextArea.js";
import { startTokenRefreshInterval } from "./utils/auth-token/refreshToken.js"
import SkillsTest from "./components/Job/jobPosting/skill_test";
import { SiteError } from "./Redux/ActionCreator";
import { ToastContainer, toast } from "react-toastify";
import NotificationPage from "./components/Notification/index";
import SubscriptionPage from "./components/subscription/index.jsx";
import Upgrade from "./components/subscription/upgrade";
import SelectPlan from "./components/subscription/select.jsx";
import DashboardCandidates from "./components/Dashboard/candidate.jsx";
import AddPaymentMethod from "./components/subscription/updatePaymentMethod.jsx"
import TestDetails from "./components/Job/test_details.jsx";
import BusinessPlan from "./components/business_plan/index.jsx";
import BusinessPlanPage from "./components/business_plan/pages/index.jsx";
import BusinessPlanPreview from "./components/business_plan/pages/preview.jsx";

function App() {
  const navigate = useNavigate();
  const profile_state = useSelector((prev) => prev.login);
  const lastPage = useSelector(e => e.lastPage);
  const userInfo = useSelector((e) => e.userInfo);

  const analytics = Analytics({
    app: 'Moilapp',
    plugins: [
      segmentPlugin({
        writeKey: '5ohjq1GtYDnbs28HuF4kYpFRe6XHiJ2S',
      }),
    ],
  });

  useEffect(() => {
    let intervalId;

    if (profile_state.isAuthenticated) {
      // Start the token refresh interval
      intervalId = startTokenRefreshInterval();
    }

    // Cleanup function to clear the interval when the component unmounts
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [profile_state.isAuthenticated]);

  analytics.page();
  let token = localStorage.getItem("token");

  return (
    <AnalyticsProviderContext analytics={analytics}>
      <div className="app">
        <main className="content">
          <Suspense
            fallback={<LoaderModal text="Our AI is loading your data" />}
          >
            <Routes>
              <Route path="/" element={<HireLogin />} />
              <Route
                path="/profile/beta/*"
                element={
                  <ProfileProtected>
                    <PageTrackingHOC>
                      <EmployerProfileView />
                    </PageTrackingHOC>
                  </ProfileProtected>
                }
              />
              <Route
                path="verify-phone"
                element={
                  <ProtectedRoute>
                    <PageTrackingHOC>
                      <PhoneVerification />
                    </PageTrackingHOC>
                  </ProtectedRoute>
                }
              />
              <Route
                path="business"
                element={
                  <ProtectedRoute>
                    <PageTrackingHOC>
                      <HomeTopBarOnlyDashboard />
                    </PageTrackingHOC>
                  </ProtectedRoute>
                }
              >
                <Route
                  path="plan-preview/:planId"
                  element={
                    <ProtectedRoute>
                      <PageTrackingHOC>
                        <BusinessPlanPreview />
                      </PageTrackingHOC>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="create-plan"
                  element={
                    <ProtectedRoute>
                      <PageTrackingHOC>
                        <BusinessPlanPage />
                      </PageTrackingHOC>
                    </ProtectedRoute>
                  }
                />
              </Route>
              {/* Employer route start */}
              <Route
                path="/employer"
                element={
                  <ProtectedRoute>
                    <PageTrackingHOC>
                      <EmployerHomeDashboard />
                    </PageTrackingHOC>
                  </ProtectedRoute>
                }
              >
                <Route
                  path="business-plan"
                  element={
                    <ProtectedRoute>
                      <PageTrackingHOC>
                        <BusinessPlan />
                      </PageTrackingHOC>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="recommended"
                  element={
                    <ProtectedRoute>
                      <PageTrackingHOC>
                        <DashboardCandidates />
                      </PageTrackingHOC>
                    </ProtectedRoute>
                  }
                />
                {/* New path added by BigSam  */}
                <Route
                  path="form/*"
                  element={
                    <ProtectedRoute>
                      <PageTrackingHOC>
                        <EmployerForm />
                      </PageTrackingHOC>
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="profile"
                  element={
                    <ProtectedRoute>
                      <PageTrackingHOC>
                        <EmployerProfile />
                      </PageTrackingHOC>
                    </ProtectedRoute>
                  }
                />

                {/* <Route
                      path="application/details"
                      element={
                        <ProtectedRoute>
                          <ApplicationDetails />
                        </ProtectedRoute>
                      }
                    /> */}

                <Route
                  path="create-job/"
                  element={
                    <ProtectedRoute>
                      <PageTrackingHOC>
                        <JobPosting />
                      </PageTrackingHOC>
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="upload/"
                  element={
                    <ProtectedRoute>
                      <PageTrackingHOC>
                        <UploadDoc />
                      </PageTrackingHOC>
                    </ProtectedRoute>
                  }
                />


                <Route
                  path="update-job/:jobName"
                  element={
                    <ProtectedRoute>
                      <PageTrackingHOC>
                        <JobPosting />
                      </PageTrackingHOC>
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="create-job/preview"
                  element={
                    <ProtectedRoute>
                      <PageTrackingHOC>
                        <JobPostPreviewDetails />
                      </PageTrackingHOC>
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="welcome"
                  element={
                    <ProtectedRoute>
                      <PageTrackingHOC>
                        <EmployerDashboard />
                      </PageTrackingHOC>
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="dashboard"
                  element={
                    <ProtectedRoute>
                      <PageTrackingHOC>
                        <RecruiterDashboard />
                      </PageTrackingHOC>
                    </ProtectedRoute>
                  }
                />

                {/* <Route
                      path=":profile_id/share"
                      element={
                        <ProtectedRoute>
                          <PageTrackingHOC>
                            <ShareProfile />
                          </PageTrackingHOC>
                        </ProtectedRoute>
                      }
                    /> */}

                <Route
                  path="jobs"
                  element={
                    <ProtectedRoute>
                      <PageTrackingHOC>
                        <EmployerJobs />
                      </PageTrackingHOC>
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="jobs/:jobName"
                  element={
                    <ProtectedRoute>
                      <PageTrackingHOC>
                        <JobsCandidate />
                      </PageTrackingHOC>
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="jobs/match"
                  element={
                    // <ProtectedRoute>
                    <JobMatch />
                    // </ProtectedRoute>
                  }
                />

                <Route
                  path="jobs/:jobName/candidates/:profileId/:applicationDataIndexNum"
                  element={
                    <ProtectedRoute>
                      <PageTrackingHOC>
                        <ApplicationDetails />
                      </PageTrackingHOC>
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="jobs/:jobName/candidates/:profileId/:applicationDataIndexNum/testpreview"
                  element={
                    <ProtectedRoute>
                      <PageTrackingHOC>
                        <TestDetails />
                      </PageTrackingHOC>
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="inbox"
                  element={
                    <ProtectedRoute>
                      <PageTrackingHOC>
                        <EmployerInbox />
                      </PageTrackingHOC>
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="inbox/:receiverId"
                  element={
                    <ProtectedRoute>
                      <PageTrackingHOC>
                        <EmployerInboxUser />
                      </PageTrackingHOC>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="inbox/:receiverId/decisionCard"
                  element={
                    <ProtectedRoute>
                      <PageTrackingHOC>
                        <EmployerDecisionCardRedesign />
                      </PageTrackingHOC>
                    </ProtectedRoute>
                  }
                />

                {/* <Route
                  path="subscribe"
                  element={
                    <ProtectedRoute>
                      <PageTrackingHOC>
                        <SubscriptionPage />
                      </PageTrackingHOC>
                    </ProtectedRoute>
                  }
                /> */}
                <Route
                  path="subscribe"
                  element={
                    <ProtectedRoute>
                      <PageTrackingHOC>
                        <StripeComponent props={{ children: <SubscriptionPage /> }} />
                      </PageTrackingHOC>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="subscribe/upgrade"
                  element={
                    <ProtectedRoute>
                      <PageTrackingHOC>
                        <StripeComponent props={{ children: <Upgrade /> }} />
                      </PageTrackingHOC>
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="subscribe/select"
                  element={
                    <ProtectedRoute>
                      <PageTrackingHOC>
                        <StripeComponent props={{ children: <SelectPlan /> }} />
                      </PageTrackingHOC>
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="subscribe/update-method"
                  element={
                    <ProtectedRoute>
                      <PageTrackingHOC>
                        <StripeComponent props={{
                          children:
                            <AddPaymentMethod />
                        }} />
                      </PageTrackingHOC>
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="notifications"
                  element={
                    <NotificationPage />
                  }
                />
              </Route>
              {/* Employer route ends */}

              {/* General route start */}
              <Route
                path="update/*"
                element={
                  <ProtectedRoute>
                    <PageTrackingHOC>
                      <EmployerProfilePhoto />
                    </PageTrackingHOC>
                  </ProtectedRoute>
                }
              />
              <Route path="/authenticate" element={
                <AuthPage />}>
                <Route path="forgot" index element={
                  <Forgot />
                } />
                <Route path="reset" element={
                  <Reset />} />
              </Route>
              <Route path="/login" element={
                <HireLogin />} />
              <Route path="/register" element={
                <HireRegister />} />
              <Route
                path="/logout"
                element={
                  <ProtectedRoute>
                    <Logout />
                  </ProtectedRoute>
                }
              />
              {/* <Route
                path="/payment"
                element={
                  <ProtectedRoute>
                    <StripeComponent />
                  </ProtectedRoute>
                }
              /> */}
              <Route
                path="/resume/:cvID"
                element={
                  <ProtectedRoute>
                    <PageTrackingHOC>
                      <UserPreviewDetails />
                    </PageTrackingHOC>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/decisionCardRedesign"
                element={
                  <EmployerDecisionCardRedesign />
                }
              />

              {/* General route ends */}
              <Route path="*" element={<NotFoundPage />} />
              {/* General route ends */}
            </Routes>
          </Suspense>
        </main>
        <ToastContainer />
      </div>
    </AnalyticsProviderContext>
  );
}

export default App;