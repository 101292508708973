 import { useState } from 'react';
import BusinessPlanWelcome from './welcome';
import BusinessOverview1 from './overview-1';
import BusinessOverview2 from './overview-2';
import BusinessOverview3 from './overview-3';
import BusinessOverview4 from './overview-4';
import BusinessOverview5 from './overview-5';
import BusinessOverview6 from './overview-6';
import BusinessMarketAndCompetitors1 from './competitor-1';
import BusinessMarketAndCompetitors2 from './competitor-2';
import BusinessMarketAndCompetitors3 from './competitor-3';
import BusinessMarketAndCompetitors4 from './competitor-4';
import BusinessMarketAndCompetitors5 from './competitor-5';
import BusinessTeamStructure1 from './structure-1';
import BusinessTeamStructure2 from './structure-2';
import BusinessTeamStructure3 from './structure-3';
import BusinessMarketingSales1 from './marketing-1';
import BusinessMarketingSales2 from './marketing-2';
import BusinessMarketingSales3 from './marketing-3';
import BusinessMarketingSales4 from './marketing-4';
import BusinessMarketingSales5 from './marketing-5';
import BusinessFinancial1 from './financial-1';
import BusinessFinancial2 from './financial-2';
import BusinessFinancial3 from './financial-3';
import BusinessFinancial4 from './financial-4';
import BusinessFinancial5 from './financial-5';
import BusinessInsight1 from './insight-1';
import BusinessInsight2 from './insight-2';
import FinalThought from './final-thought';

const BusinessPlanPage = () => {
  const [step, setStep] = useState(0);

  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  return (
    <div className='w-full w-[100vw] overflow-y-scroll ScrollOnly h-calc-70'>
      {step === 0 && <BusinessPlanWelcome nextStep={nextStep} />}
      {step === 1 && <BusinessOverview1 prevStep={prevStep} nextStep={nextStep} />}
      {step === 2 && <BusinessOverview2 prevStep={prevStep} nextStep={nextStep} />}
      {step === 3 && <BusinessOverview3 prevStep={prevStep} nextStep={nextStep} />}
      {step === 4 && <BusinessOverview4 prevStep={prevStep} nextStep={nextStep} />}
      {step === 5 && <BusinessOverview5 prevStep={prevStep} nextStep={nextStep} />}
      {step === 6 && <BusinessOverview6 prevStep={prevStep} nextStep={nextStep} />}
      {step === 7 && <BusinessMarketAndCompetitors1 prevStep={prevStep} nextStep={nextStep} />}
      {step === 8 && <BusinessMarketAndCompetitors2 prevStep={prevStep} nextStep={nextStep} />}
      {step === 9 && <BusinessMarketAndCompetitors3 prevStep={prevStep} nextStep={nextStep} />}
      {step === 10 && <BusinessMarketAndCompetitors4 prevStep={prevStep} nextStep={nextStep} />}
      {step === 11 && <BusinessMarketAndCompetitors5 prevStep={prevStep} nextStep={nextStep} />}
      {step === 12 && <BusinessTeamStructure1 prevStep={prevStep} nextStep={nextStep} />}
      {step === 13 && <BusinessTeamStructure2 prevStep={prevStep} nextStep={nextStep} />}
      {step === 14 && <BusinessTeamStructure3 prevStep={prevStep} nextStep={nextStep} />}
      {step === 15 && <BusinessMarketingSales1 prevStep={prevStep} nextStep={nextStep} />}
      {step === 16 && <BusinessMarketingSales2 prevStep={prevStep} nextStep={nextStep} />}
      {step === 17 && <BusinessMarketingSales3 prevStep={prevStep} nextStep={nextStep} />}
      {step === 18 && <BusinessMarketingSales4 prevStep={prevStep} nextStep={nextStep} />}
      {step === 19 && <BusinessMarketingSales5 prevStep={prevStep} nextStep={nextStep} />}
      {step === 20 && <BusinessFinancial1 prevStep={prevStep} nextStep={nextStep} />}
      {step === 21 && <BusinessFinancial2 prevStep={prevStep} nextStep={nextStep} />}
      {step === 22 && <BusinessFinancial3 prevStep={prevStep} nextStep={nextStep} />}
      {step === 23 && <BusinessFinancial4 prevStep={prevStep} nextStep={nextStep} />}
      {step === 24 && <BusinessFinancial5 prevStep={prevStep} nextStep={nextStep} />}
      {step === 25 && <BusinessInsight1 prevStep={prevStep} nextStep={nextStep} />}
      {step === 26 && <BusinessInsight2 prevStep={prevStep} nextStep={nextStep} />}
      {step === 27 && <FinalThought prevStep={prevStep} />}
    </div>
  );
};

export default BusinessPlanPage;
