import React from "react";
import TopBar from "../../components/topbar/Top-Bar";
import { Outlet } from "react-router-dom";

const HomeTopBarOnlyDashboard = () => {
  // console.log("header", stat.profile_status.profile_status);
  return (
    <div className={`Dashboard bg-[#FAFAFA] grid grid-cols-auto h-full grid-rows-dash bg-tet-912 overflow-x-hidden overflow-y-hidden`}>
      <TopBar topBarShowMenu={true} />
      <Outlet />
    </div>
  );
};

export default HomeTopBarOnlyDashboard;
