import React, { useState, useRef, useEffect } from "react";

import { savelink } from "../../../Redux/ActionCreator";
import { motion, AnimatePresence } from "framer-motion";
import { baseURL1 } from "../../../Redux/baseURL";
import { useDispatch } from "react-redux";
import { Eye, EyeOff } from 'lucide-react';

export default function SignupForm(props) {
	const dispatch = useDispatch();
	const [focus, setFocused] = useState({ password: false, confirm: false });
	const [passwordView, setpasswordView] = useState(false);
	const [confirmpasswordView, setConfirmpasswordView] = useState(false);
	const passwordRef = useRef();
	const confirmRef = useRef();
	const [authMethod, setAuthMethod] = useState("default"); // default, email, phone


	useEffect(() => {
		// console.log("props", props.link);
		if (props.link !== undefined && props.link !== null) {
			dispatch(savelink(baseURL1 + props.link));
		}
	}, [props]);

	// Animation variants
	const containerVariants = {
		hidden: { opacity: 0 },
		visible: {
			opacity: 1,
			transition: { duration: 0.3 }
		},
		exit: {
			opacity: 0,
			transition: { duration: 0.2 }
		}
	};

	const handleFocus = (toFocus) => {
		if (toFocus === "password") {
			setFocused({ confirm: false, [toFocus]: true });
			passwordRef.current.focus();
		} else {
			setFocused({ password: false, [toFocus]: true });
			confirmRef.current.focus();
		}
	};

	const handlePasswordView = () => {
		setpasswordView(!passwordView);
	};

	const handleConfirmPasswordView = () => {
		setConfirmpasswordView(!confirmpasswordView);
	};


	const handleLoginClick = () => {
		if (props.switchStat !== undefined) {
			props.switch(!props.switchStat);
		} else {
			window.location.assign(baseURL1 + `/login?lg=${props?.lg}`)
		}
	}


	return (
		<div className="border border-pry-900 border-opacity-10 border-[6px] w-full max-w-[523px] py-4 bg-white rounded-2xl border-pry-900 bg-opacity-100 border-opacity-10 ">
			<div className="max-w-md w-full mx-auto py-6 px-4 bg-white flex justify-center gap-y-6 rounded-3xl">
				<div className={`flex w-full ${authMethod === "default" ? 'max-w-[350px]' : ''} gap-y-2 flex-col items-center mb-6`}>
					<p className="text-2xl font-semibold font-bold mb-2 flex items-center gap-2">
						Welcome to <img
							src="https://res.cloudinary.com/drlcisipo/image/upload/v1705704261/Website%20images/logo_gox0fw.png"
							alt="Moil Logo"
							className={`w-[60px]`} />
					</p>

					<AnimatePresence mode="wait">
						{authMethod === "default" && (
							<motion.div
								key="default"
								variants={containerVariants}
								initial="hidden"
								animate="visible"
								exit="exit"
								className="w-full flex flex-col gap-4"
							>
								<p className="text-center text-[#000000DE] text-sm leading-[1.1] font-semibold">
									Please select one of the options below to get started.
								</p>

								<button
									className="flex items-center justify-center gap-3 py-3 px-4 w-full rounded-xl bg-red-50 hover:bg-red-100 transition-colors border border-red-100 text-gray-800"
									onClick={props.googleSignup}
								>
									<svg width="24" height="24" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M16.8892 6.53037C20.0605 6.53037 22.1998 7.90025 23.4195 9.04494L28.186 4.39111C25.2585 1.67012 21.4492 0 16.8892 0C10.2837 0 4.57904 3.79062 1.80176 9.30766L7.2625 13.5486C8.63238 9.47654 12.423 6.53037 16.8892 6.53037Z" fill="#EA4335" />
										<path d="M33.102 17.2644C33.102 15.8758 32.9894 14.8624 32.7455 13.8116H16.8887V20.0792H26.1963C26.0087 21.6368 24.9953 23.9824 22.7435 25.5587L28.0729 29.6871C31.263 26.741 33.102 22.4062 33.102 17.2644Z" fill="#4285F4" />
										<path d="M7.28099 20.229C6.92444 19.1782 6.71803 18.0522 6.71803 16.8888C6.71803 15.7253 6.92445 14.5994 7.26222 13.5485L1.80148 9.30756C0.65679 11.5969 0 14.1678 0 16.8888C0 19.6098 0.65679 22.1806 1.80148 24.47L7.28099 20.229Z" fill="#FBBC05" />
										<path d="M16.889 33.7777C21.449 33.7777 25.2771 32.2765 28.0732 29.6868L22.7438 25.5584C21.3176 26.553 19.4035 27.2473 16.889 27.2473C12.4228 27.2473 8.63216 24.3012 7.28105 20.2291L1.82031 24.4701C4.5976 29.9871 10.2835 33.7777 16.889 33.7777Z" fill="#34A853" />
									</svg>
									<span className="font-medium text-[#22263A] leading-[1.3] font-medium">Sign up with Google</span>
								</button>

								<div className="flex items-center gap-2 my-2">
									<div className="h-px bg-gray-200 flex-1"></div>
									<span className="text-gray-500 text-sm">or</span>
									<div className="h-px bg-gray-200 flex-1"></div>
								</div>

								<button
									className="flex items-center justify-center gap-3 py-3 px-4 w-full rounded-xl bg-blue-50 hover:bg-blue-100 transition-colors border border-blue-100 text-gray-800"
									onClick={() => setAuthMethod("email")}
								>
									<svg width="21" height="16" viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M18.5 0H2.5C1.4 0 0.51 0.9 0.51 2L0.5 14C0.5 15.1 1.4 16 2.5 16H18.5C19.6 16 20.5 15.1 20.5 14V2C20.5 0.9 19.6 0 18.5 0ZM18.5 4L10.5 9L2.5 4V2L10.5 7L18.5 2V4Z" fill="#22263A" />
									</svg>
									<span className="font-medium">Sign up with email address</span>
								</button>

								<div className="flex items-center gap-2 my-2">
									<div className="h-px bg-gray-200 flex-1"></div>
									<span className="text-gray-500 text-sm">or</span>
									<div className="h-px bg-gray-200 flex-1"></div>
								</div>

								<button
									className="flex items-center font-medium justify-center gap-3 py-3 px-4 w-full border-2 border-[#FF66331A] rounded-xl bg-[#FF6633] text-white"
									onClick={() => setAuthMethod("phone")}
								>
									<svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M4.12 7.79C5.56 10.62 7.88 12.93 10.71 14.38L12.91 12.18C13.18 11.91 13.58 11.82 13.93 11.94C15.05 12.31 16.26 12.51 17.5 12.51C18.05 12.51 18.5 12.96 18.5 13.51V17C18.5 17.55 18.05 18 17.5 18C8.11 18 0.5 10.39 0.5 1C0.5 0.45 0.95 0 1.5 0H5C5.55 0 6 0.45 6 1C6 2.25 6.2 3.45 6.57 4.57C6.68 4.92 6.6 5.31 6.32 5.59L4.12 7.79Z" fill="white" />
									</svg>

									<span className="font-medium">Sign up with phone number</span>
								</button>

								<p className="text-center text-sm mt-4">
									Already have an account?
									<span
										onClick={handleLoginClick}
										className="text-[#5843BD] font-medium ml-1 cursor-pointer hover:underline"
									>
										Sign in
									</span>
								</p>
							</motion.div>
						)}

						{authMethod === "email" && (
							<motion.div
								key="email"
								variants={containerVariants}
								initial="hidden"
								animate="visible"
								exit="exit"
								className="w-full gap-y-4 flex flex-col"
							>
								<p className="text-center font-semibold leading-[1.1] text-[#000000DE] mb-6">
									Sign up with your email address
								</p>

								<div className="bg-blue-50 p-6 rounded-xl">
									<div className="mb-4">
										<label className="text-sm font-normal italic text-black" htmlFor="email">
											Type your <span className="font-semibold">email address</span> in the box below.
										</label>
										<div className="relative">
											<svg width="20" height="16" className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M18 0H2C0.9 0 0.00999999 0.9 0.00999999 2L0 14C0 15.1 0.9 16 2 16H18C19.1 16 20 15.1 20 14V2C20 0.9 19.1 0 18 0ZM18 4L10 9L2 4V2L10 7L18 2V4Z" fill="#22263A" />
											</svg>
											<input
												type="text"
												id="email"
												className="pl-10 w-full p-4 border placeholder:italic placeholder:text-[#00000080] border-indigo-400 rounded-lg focus:ring-2 focus:ring-[#FF6633] focus:border-[#FF6633]"
												placeholder="your@email.com"
												value={props.username}
												onChange={(e) => props.setUserCred((prev) => ({
													...prev, username: e.target.value
												}))}
											/>
										</div>
									</div>

									<div className="mb-4">
										<label className="block text-sm font-medium text-black  mb-1" htmlFor="password" onClick={handleFocus}>
											Type your password in the box below
										</label>
										<div className="relative">
											<input
												ref={passwordRef}
												type={passwordView ? "text" : "password"}
												id="password"
												className="w-full p-4 border border-indigo-400 placeholder:italic placeholder:text-[#00000080] rounded-lg focus:ring-2 focus:ring-[#FF6633] focus:border-[#FF6633]"
												placeholder="Enter your password"
												value={props.password}
												onChange={(e) => props.setUserCred((prev) => ({
													...prev, password: e.target.value
												}))}
											/>
											<button
												type="button"
												onClick={handlePasswordView}
												className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500"
											>
												{passwordView ? <EyeOff size={18} /> : <Eye size={18} />}
											</button>
										</div>
									</div>


									<div className="mb-4">
										<label className="block text-sm font-medium text-black mb-1" htmlFor="password" onClick={handleFocus}>
											Type your password again
										</label>
										<div className="relative">
											<input
												ref={passwordRef}
												type={confirmpasswordView ? "text" : "password"}
												id="password"
												className="w-full p-4 placeholder:italic placeholder:text-[#00000080] border border-indigo-400  rounded-lg focus:ring-2 focus:ring-[#FF6633] focus:border-[#FF6633]"
												placeholder="type the same password here"
												value={props.confirmPassword}
												onChange={(e) => props.setUserCred((prev) => ({
													...prev, confirmPassword: e.target.value
												}))}
											/>
											<button
												type="button"
												onClick={handleConfirmPasswordView}
												className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500"
											>
												{confirmpasswordView ? <EyeOff size={18} /> : <Eye size={18} />}
											</button>
										</div>
									</div>

									<div className="flex flex-col gap-y-2 mb-6">
										<div className="flex items-center gap-2">
											<input
												type="checkbox"
												id="remember_me"
												className="accent-purple rounded-md w-[24px] h-[24px] focus:ring-0"
											/>

											<label class="font-poppins text-xs font-normal leading-3 text-left" for="remember_me">
												Remember  me
											</label>
										</div>
										<div className="flex items-center gap-2">
											<input
												type="checkbox"
												id="privacy"
												className="accent-purple rounded-md w-[24px] h-[24px] focus:ring-0 shrink-0"
											/>
											<label className="font-poppins text-xs font-normal text-left" htmlFor="privacy">
												By signing up, I agree to Moil’s{' '}
												<span className="text-[#5843BD]">Privacy Agreement</span> and{' '}
												<span className="text-[#5843BD]">Terms of Service</span>
											</label>
										</div>
									</div>

									<button
										className="w-full py-3 px-4 bg-gradient text-white font-medium rounded-lg hover:opacity-90 transition-opacity"
										onClick={props.handleSubmit}
									>
										Sign up
									</button>
								</div>

								<p className="text-center text-sm mt-4">
									Already have an account?
									<span
										onClick={handleLoginClick}
										className="text-[#5843BD] font-medium ml-1 italic cursor-pointer hover:underline"
									>
										Sign in
									</span>
								</p>

								<div className="flex flex-col gap-y-2 items-center">
									<p className="text-center text-sm mt-4">
										You can also sign up with
									</p>

									<div className="flex flex-col w-full md:w-max md:flex-row gap-3 items-center">
										<button
											className="flex items-center justify-center gap-3 py-3 px-2 w-full md:w-max rounded-xl bg-red-50 hover:bg-red-100 transition-colors border border-red-100 text-gray-800"
											onClick={props.googleSignup}
										>
											<svg width="24" height="24" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M16.8892 6.53037C20.0605 6.53037 22.1998 7.90025 23.4195 9.04494L28.186 4.39111C25.2585 1.67012 21.4492 0 16.8892 0C10.2837 0 4.57904 3.79062 1.80176 9.30766L7.2625 13.5486C8.63238 9.47654 12.423 6.53037 16.8892 6.53037Z" fill="#EA4335" />
												<path d="M33.102 17.2644C33.102 15.8758 32.9894 14.8624 32.7455 13.8116H16.8887V20.0792H26.1963C26.0087 21.6368 24.9953 23.9824 22.7435 25.5587L28.0729 29.6871C31.263 26.741 33.102 22.4062 33.102 17.2644Z" fill="#4285F4" />
												<path d="M7.28099 20.229C6.92444 19.1782 6.71803 18.0522 6.71803 16.8888C6.71803 15.7253 6.92445 14.5994 7.26222 13.5485L1.80148 9.30756C0.65679 11.5969 0 14.1678 0 16.8888C0 19.6098 0.65679 22.1806 1.80148 24.47L7.28099 20.229Z" fill="#FBBC05" />
												<path d="M16.889 33.7777C21.449 33.7777 25.2771 32.2765 28.0732 29.6868L22.7438 25.5584C21.3176 26.553 19.4035 27.2473 16.889 27.2473C12.4228 27.2473 8.63216 24.3012 7.28105 20.2291L1.82031 24.4701C4.5976 29.9871 10.2835 33.7777 16.889 33.7777Z" fill="#34A853" />
											</svg>
											<span className="font-medium text-[#22263A] leading-[1.3] font-medium">Google</span>
										</button>

										<div className="flex w-full items-center gap-2 my-2">
											<div className="h-px w-full md:hidden bg-gray-200 flex-1"></div>
											<span className="text-gray-500 text-sm">or</span>
											<div className="h-px w-full md:hidden bg-gray-200 flex-1"></div>
										</div>

										<button
											className="flex items-center font-medium justify-center gap-3 py-3 px-2 w-full md:w-max border-2 border-[#FF66331A] rounded-xl bg-[#FF6633] text-white"
											onClick={() => setAuthMethod("phone")}
										>
											<svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M4.12 7.79C5.56 10.62 7.88 12.93 10.71 14.38L12.91 12.18C13.18 11.91 13.58 11.82 13.93 11.94C15.05 12.31 16.26 12.51 17.5 12.51C18.05 12.51 18.5 12.96 18.5 13.51V17C18.5 17.55 18.05 18 17.5 18C8.11 18 0.5 10.39 0.5 1C0.5 0.45 0.95 0 1.5 0H5C5.55 0 6 0.45 6 1C6 2.25 6.2 3.45 6.57 4.57C6.68 4.92 6.6 5.31 6.32 5.59L4.12 7.79Z" fill="white" />
											</svg>

											<span className="font-medium">Phone</span>
										</button>
									</div>
								</div>

							</motion.div>
						)}

						{authMethod === "phone" && (
							<motion.div
								key="phone"
								variants={containerVariants}
								initial="hidden"
								animate="visible"
								exit="exit"
								className="w-full flex flex-col gap-y-4"
							>
								<p className="text-center text-gray-600 mb-6">
									Sign in with your phone number
								</p>

								<div className="bg-orange-50 p-6 rounded-xl">
									<div className="mb-4">
										<label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="phone">
											Type your <span className="font-semibold">phone number</span> in the box below.
										</label>
										<div className="relative">
											<svg width="18" height="18" className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M3.62 7.79C5.06 10.62 7.38 12.93 10.21 14.38L12.41 12.18C12.68 11.91 13.08 11.82 13.43 11.94C14.55 12.31 15.76 12.51 17 12.51C17.55 12.51 18 12.96 18 13.51V17C18 17.55 17.55 18 17 18C7.61 18 0 10.39 0 1C0 0.45 0.45 0 1 0H4.5C5.05 0 5.5 0.45 5.5 1C5.5 2.25 5.7 3.45 6.07 4.57C6.18 4.92 6.1 5.31 5.82 5.59L3.62 7.79Z" fill="#FF6633" />
											</svg>

											<input
												type="text"
												id="phone"
												className="pl-10 w-full py-2.5 px-3 border border-[#FF663354] placeholder:italic placeholder:text-[#00000080] rounded-lg focus:ring-2 focus:ring-[#FF6633] focus:border-[#FF6633]"
												placeholder="+1234567890"
												value={props.username}
												onChange={(e) => props.setUserCred((prev) => ({
													...prev, username: e.target.value
												}))}
											/>
										</div>
									</div>

									<div className="mb-4">
										<label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="password-phone" onClick={handleFocus}>
											Type your password in the box below
										</label>
										<div className="relative">
											<input
												ref={passwordRef}
												type={passwordView ? "text" : "password"}
												id="password-phone"
												className="w-full py-2.5 px-3 border placeholder:italic placeholder:text-[#00000080] border-[#FF663354] rounded-lg focus:ring-2 focus:ring-[#FF6633] focus:border-[#FF6633]"
												placeholder="password"
												value={props.password}
												onChange={(e) => props.setUserCred((prev) => ({
													...prev, password: e.target.value
												}))}
											/>
											<button
												type="button"
												onClick={handlePasswordView}
												className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500"
											>
												{passwordView ? <EyeOff size={18} /> : <Eye size={18} />}
											</button>
										</div>
									</div>

									<div className="mb-4">
										<label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="password-phone" onClick={handleFocus}>
											Type your password again
										</label>
										<div className="relative">
											<input
												ref={passwordRef}
												type={confirmpasswordView ? "text" : "password"}
												id="password-phone"
												className="w-full py-2.5 px-3 placeholder:italic placeholder:text-[#00000080] border border-[#FF663354] rounded-lg focus:ring-2 focus:ring-[#FF6633] focus:border-[#FF6633]"
												placeholder="type the same password here"
												value={props.confirmPassword}
												onChange={(e) => props.setUserCred((prev) => ({
													...prev, confirmPassword: e.target.value
												}))}
											/>
											<button
												type="button"
												onClick={handleConfirmPasswordView}
												className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500"
											>
												{confirmpasswordView ? <EyeOff size={18} /> : <Eye size={18} />}
											</button>
										</div>
									</div>

									<div className="flex flex-col gap-y-2 mb-6">
										<div className="flex items-center gap-2">
											<input
												type="checkbox"
												id="remember_me"
												className="accent-purple rounded-md w-[24px] h-[24px] focus:ring-0"
											/>

											<label class="font-poppins text-xs font-normal leading-3 text-left" for="remember_me">
												Remember  me
											</label>
										</div>

										<div className="flex items-center gap-2">
											<input
												type="checkbox"
												id="privacy"
												className="accent-purple rounded-md w-[24px] h-[24px] focus:ring-0 shrink-0"
											/>
											<label className="font-poppins text-xs font-normal text-left" htmlFor="privacy">
												By signing up, I agree to Moil’s{' '}
												<span className="text-[#5843BD]">Privacy Agreement</span> and{' '}
												<span className="text-[#5843BD]">Terms of Service</span>
											</label>
										</div>
									</div>

									<button
										className="w-full py-3 px-4 bg-gradient text-white font-medium rounded-lg hover:opacity-90 transition-opacity"
										onClick={props.handleSubmit}
									>
										Sign up
									</button>

								</div>

								<p className="text-center text-sm mt-4">
									Already have an account?
									<span
										onClick={handleLoginClick}
										className="text-[#5843BD] font-medium ml-1 italic cursor-pointer hover:underline"
									>
										Sign in
									</span>
								</p>

								<div className="flex flex-col gap-y-2 items-center">
									<p className="text-center text-sm mt-4">
										You can also sign up with
									</p>

									<div className="flex flex-col w-full md:w-max md:flex-row gap-3 items-center">
										<button
											className="flex items-center justify-center w-full md:w-max gap-3 py-3 px-2 rounded-xl bg-red-50 hover:bg-red-100 transition-colors border border-red-100 text-gray-800"
											onClick={props.googleSignup}
										>
											<svg width="24" height="24" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M16.8892 6.53037C20.0605 6.53037 22.1998 7.90025 23.4195 9.04494L28.186 4.39111C25.2585 1.67012 21.4492 0 16.8892 0C10.2837 0 4.57904 3.79062 1.80176 9.30766L7.2625 13.5486C8.63238 9.47654 12.423 6.53037 16.8892 6.53037Z" fill="#EA4335" />
												<path d="M33.102 17.2644C33.102 15.8758 32.9894 14.8624 32.7455 13.8116H16.8887V20.0792H26.1963C26.0087 21.6368 24.9953 23.9824 22.7435 25.5587L28.0729 29.6871C31.263 26.741 33.102 22.4062 33.102 17.2644Z" fill="#4285F4" />
												<path d="M7.28099 20.229C6.92444 19.1782 6.71803 18.0522 6.71803 16.8888C6.71803 15.7253 6.92445 14.5994 7.26222 13.5485L1.80148 9.30756C0.65679 11.5969 0 14.1678 0 16.8888C0 19.6098 0.65679 22.1806 1.80148 24.47L7.28099 20.229Z" fill="#FBBC05" />
												<path d="M16.889 33.7777C21.449 33.7777 25.2771 32.2765 28.0732 29.6868L22.7438 25.5584C21.3176 26.553 19.4035 27.2473 16.889 27.2473C12.4228 27.2473 8.63216 24.3012 7.28105 20.2291L1.82031 24.4701C4.5976 29.9871 10.2835 33.7777 16.889 33.7777Z" fill="#34A853" />
											</svg>
											<span className="font-medium text-[#22263A] leading-[1.3] font-medium">Google</span>
										</button>

										<div className="flex w-full items-center gap-2 my-2">
											<div className="h-px w-full md:hidden bg-gray-200 flex-1"></div>
											<span className="text-gray-500 text-sm">or</span>
											<div className="h-px w-full md:hidden bg-gray-200 flex-1"></div>
										</div>

										<button
											className="flex items-center justify-center w-full md:w-max  gap-3 py-3 px-2 rounded-xl bg-blue-50 hover:bg-blue-100 transition-colors border border-blue-100 text-gray-800"
											onClick={() => setAuthMethod("email")}
										>
											<svg width="21" height="16" viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M18.5 0H2.5C1.4 0 0.51 0.9 0.51 2L0.5 14C0.5 15.1 1.4 16 2.5 16H18.5C19.6 16 20.5 15.1 20.5 14V2C20.5 0.9 19.6 0 18.5 0ZM18.5 4L10.5 9L2.5 4V2L10.5 7L18.5 2V4Z" fill="#22263A" />
											</svg>
											<span className="font-medium">Email</span>
										</button>
									</div>
								</div>
							</motion.div>
						)}
					</AnimatePresence>
				</div>
			</div>
		</div>
	)
}